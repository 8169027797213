import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import blLogo from './../assets/icons/Logo_White.svg';
import { authenticateService, storeToken, storeUserInfo } from "../services/authService";
import { AuthContext } from "../services/context/Auth/authContext";


const LoginComponent = () => {

    window.scrollTo(0, 0)
    const { setLoggedUserName } = useContext(AuthContext)
    const { setIsCanabisApp } = useContext(AuthContext)
    const nav = useNavigate();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        setUserName('')
        setPassword('')
        document.getElementsByTagName('body')[0].classList.add('vista_login_bg1');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('vista_login_bg1')
        }
    }, [])

    const loginUser = async (e) => {
        e.preventDefault();
        let credential = {
            userName: userName,
            password: password
        }
        const resp = await authenticateService(credential);
        if(resp && resp.success){
            setLoggedUserName(userName === 'sd_002' ? 'Sarathi Dutta' : 'Apurva Pandey')
            setIsCanabisApp(userName === 'sd_002')
            const obj = {
                userName: userName === 'sd_002' ? 'Sarathi Dutta' : 'Apurva Pandey',
                isCanabisApp: userName === 'sd_002'
            }
            storeUserInfo(JSON.stringify(obj))
            storeToken(resp.data.sessionToken);
            nav('/dashboard')
        }
        else {
            alert('Invalid Credential!')
        }
    }

    return (
        <>
            <div class="bg-image"></div>
            <div class="bg-text-cover">
                <div class="d-flex align-items-center bg-text border">
                    <header>
                    <div class="d-flex justify-content-between px-5 text-muted pt-4">
                        <img src="https://www.brownloop.com/wp-content/uploads/2024/07/brownloop-logo.svg" height="13px"/>
                    </div>
                    </header>

                    <div class="box w-100">
                        <img src="https://blplatformapimanagerdev02.azure-api.net/static/media/Kairos_Logo.ad0ee53bce950365fd7e7357f758da72.svg" height="65px" class="mb-3"/>
                        <h3 class="mb-4 text-gray-800">Unlock the power of <span className="bg-text-white">AI Agents</span> in Private Equity.</h3>
                        <p class="lead pt-2 d-none">
                            <a href="#" class="btn btn-lg btn-light border-0 bg-danger text-white font-weight-light">
                            <img src="https://img.icons8.com/ios11/600/FFFFFF/windows-10.png" height="18px" class="mb-1" />&nbsp;&nbsp;Signin</a>
                        </p>
                        <div className="bg-login-form-container">
                                <form onSubmit={(e) => loginUser(e)} className="user">
                                    <div className="form-group">
                                        <input type="text" onChange={(e) => setUserName(e.target.value)} value={userName} className="form-control vista_login_formElements_top" id="exampleInputEmail" placeholder="Brownloop Id" />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control vista_login_formElements_bottom" id="exampleInputPassword" placeholder="Password" />
                                    </div>
                                    <button type="submit" className="btn btn-google btn-block border-0 vista_login_formElements">
                                        Login
                                    </button>
                                </form>
                        </div>
                    </div>

                    <footer>
                        <ul class="nav justify-content-center pb-2">
                            <li class="nav-item">
                                <a class="nav-link text-muted" href="https://api.brownloop.ai/" target="_blank">Developer Portal</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-muted" href="https://www.brownloop.com/">Terms & Conditions</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-muted" href="https://www.brownloop.com/contact-us/">Get Help</a>
                            </li>
                        </ul>
                    </footer>
                </div>
            </div>  
        </>

    )
}

export default LoginComponent;